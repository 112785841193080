// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-team-mentors-js": () => import("./../src/pages/team-mentors.js" /* webpackChunkName: "component---src-pages-team-mentors-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-thesis-js": () => import("./../src/pages/thesis.js" /* webpackChunkName: "component---src-pages-thesis-js" */),
  "component---src-templates-jobs-js": () => import("./../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

